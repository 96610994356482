import swal from 'bootstrap-sweetalert';
import FileSaver from 'file-saver';
import moment from 'moment';
import { order } from 'services/order/service';
import { jobManager } from 'services/jobManager/service';
import { driver } from 'services/driver/service';
import authService from 'services/auth/service';
import { approveOrder } from 'store/actions/Orders';
import { userRole } from 'components/globalInfo/userRoles';
import orderDetailsModel from 'models/OrderDetails';
import { queryClient } from 'api';

const init = orderId => dispatch => {
  const startInit = () => ({ type: 'ORDER_DETAILS_CLEAR' });
  dispatch(startInit());
  dispatch(getOrder(orderId));
  dispatch(getBreadcrumb(orderId));
  dispatch(getPriceBreakDown(orderId));
  dispatch(getContainers(orderId));
  dispatch(getDemurrage());
};

const saveInitValue = (name, value) => ({
  type: 'ORDER_DETAILS_SAVE_INIT_VALUE',
  name,
  value,
});
const failureInitValue = error => ({
  type: 'ORDER_DETAILS_INIT_VALUE_ERROR',
  error,
});

const getOrder = orderId => dispatch => {
  order
    .get({ orderId, includeDeleted: true })
    .then(response => {
      const info = response.data.data;
      const detailsParam = { excludeOrder: true };
      jobManager
        .getJobDetails(info.details[0].jobId, detailsParam)
        .then(response => {
          dispatch(saveInitValue('jobDetails', response.data.data));
          dispatch(saveInitValue('order', info.orders[0]));
          dispatch(saveInitValue('sandTicketIds', info.sandTicketIds || []));
          dispatch(saveInitValue('commodityInfo', info.commodityInfo || []));
          dispatch(saveInitValue('details', info.details[0]));
          dispatch(saveInitValue('transfers', info.transfers));
        });
    })
    .catch(error => {
      dispatch(failureInitValue(error));
      swal(error.response.data.message, '', 'error');
    });
};

const getContainers = orderId => dispatch => {
  order
    .containers(orderId)
    .then(response => {
      dispatch(saveInitValue('containers', response.data.data.containers));
    })
    .catch(error => {
      dispatch(failureInitValue(error));
      swal(error.response.data.message, '', 'error');
    });
};

const getBreadcrumb = orderId => dispatch => {
  order
    .breadcrumb(orderId)
    .then(response => {
      dispatch(saveInitValue('breadcrumb', response.data.data));
    })
    .catch(error => {
      dispatch(failureInitValue(error));
      swal(error.response.data.message, '', 'error');
    });
};

const getPriceBreakDown = orderId => dispatch => {
  order
    .pricebreakdown(orderId)
    .then(response => {
      dispatch(saveInitValue('pricebreakdown', response.data.data.price));
      dispatch(saveInitValue('totalPrice', response.data.data.total.total));
      dispatch(saveInitValue('carrierPrice', response.data.data.total.carrierTotal));
    })
    .catch(error => {
      dispatch(saveInitValue('pricebreakdown', []));
      dispatch(saveInitValue('totalPrice', 0));
      dispatch(saveInitValue('carrierPrice', 0));
    });
};

const getDemurrage = () => dispatch => {
  order
    .getDemurrageReasons()
    .then(response => {
      dispatch(
        saveInitValue('demurrageReasons', response.data.data.demurageReasons),
      );
    })
    .catch(error => {
      dispatch(failureInitValue(error));
      swal(error.response.data.message, '', 'error');
    });
};

// order header

const approveOrderMethod = () => (dispatch, getState) => {
  const {
    orderDetails: {
      info: {
        order: { order_id },
      },
    },
  } = getState();
  return dispatch(approveOrder([order_id], false));
};

const refreshOrder = () => (dispatch, getState) => {
  const {
    orderDetails: {
      info: {
        order: { order_id },
      },
    },
  } = getState();
  dispatch(init(order_id));
};

const getTransferred = () => (dispatch, getState) => {
  const success = transferred => ({
    type: 'ORDER_DETAILS_MODAL_TRANSFERRED_SUCCESS',
    transferred,
  });
  const {
    orderDetails: {
      info: {
        order: { order_id },
      },
    },
  } = getState();
  order.transfer
    .get(order_id)
    .then(response => {
      dispatch(success(response.data.data.transfers));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};
const clearTransferred = () => ({
  type: 'ORDER_DETAILS_MODAL_TRANSFERRED_CLEAR',
});

const getHistory = (orderId) => (dispatch, getState) => {
  const success = history => ({
    type: 'ORDER_DETAILS_MODAL_HISTORY_SUCCESS',
    history,
  });
  const {
    orderDetails: {
      info: {
        order: { order_id },
      },
    },
  } = getState();

  let orderIdparam = order_id;
  if (orderId) {
    orderIdparam = orderId;
  }

  order
    .getHistory(orderIdparam)
    .then(response => {

      dispatch(success(response.data.data.logs));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const clearHistory = () => ({
  type: 'ORDER_DETAILS_MODAL_HISTORY_CLEAR',
});

// select driver modal

const submitSelectDriverModal = closeModal => (dispatch, getState) => {
  const success = () => ({
    type: 'ORDER_DETAILS_SELECT_DRIVER_MODAL_SUCCESS',
  });

  const state = getState();
  const data = {
    driverId: state.orderDetails.modalSelectDriver.selectedDriver,
    orderId: state.orderDetails.info.order.order_id,
  };

  order
    .assignDriver(data)
    .then(msg => {
      swal('Success!', '', 'success');
      dispatch(success());
      dispatch(init(data.orderId));
      closeModal();
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const setInputValueDriverModal = (value, name) => ({
  type: 'ORDER_DETAILS_SELECT_DRIVER_MODAL_SET_VALUE',
  value,
  name,
});
const clearDriverModal = () => ({
  type: 'ORDER_DETAILS_SELECT_DRIVER_MODAL_CLEAR',
});

// tabs
const changeKey = value => ({
  type: 'ORDER_DETAILS_CHANGE_TAB_KEY',
  value,
});

// sand tickets
const getSandTicketsDesign = () => (dispatch, getState) => {
  const success = sandTicketTypes => ({
    type: 'ORDER_DETAILS_INIT_TICKET_DESIGN',
    sandTicketTypes,
  });
  const state = getState();
  const {
    orderDetails: {
      info: {
        order: { order_id },
      },
    },
  } = state;

  order
    .getSandTicketTypesDesign(order_id)
    .then(response => {
      const types = response.data.data.sandTicketTypes;
      types.unshift({
        isRequired: 0,
        name: 'Unknown',
        sandTicketTypeId: 0,
      });
      dispatch(success(types));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const selectTiket = item => ({
  type: 'ORDER_DETAILS_SELECT_TICKET',
  item,
});

const clearGetST = () => ({ type: 'ORDER_DETAILS_CLEAR_GET_TICKET' });
const clearEditST = () => ({ type: 'ORDER_DETAILS_CLEAR_EDIT_TICKET' });

const deleteSandTicket = id => (dispatch, getState) => {
  const state = getState();
  const {
    orderDetails: {
      info: {
        order: { order_id },
      },
    },
  } = state;

  order
    .deleteSandTicket(order_id, id)
    .then(response => {
      dispatch(init(order_id));
      swal(response.data.message, '', 'success');

      queryClient.invalidateQueries({ queryKey: ['orders', Number(order_id) ]});
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

// preview sand ticket

const getSandTicket = (ticketId) => (dispatch, getState) => {
  const success = image => ({
    type: 'ORDER_DETAILS_MODAL_PREVIEW_INIT_IMG',
    image,
  });
  const state = getState();
  const {
    orderDetails: {
      info: {
        order: { order_id },
      },
    },
  } = state;

  order
    .getSandTicket(order_id, ticketId)
    .then(response => {
      dispatch(success(response.data));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const downloadSandTicket = () => (dispatch, getState) => {
  const state = getState();
  const {
    orderDetails: {
      sandTicket: { selectedTicket },
      modalPreviewST: { img },
    },
  } = state;

  const file = new Blob([img], { type: 'application/octet-stream' });
  FileSaver.saveAs(file, `Ticket-${selectedTicket}.png`);
};

// edit sand ticket
const editSandTicket = closeEdit => (dispatch, getState) => {
  const {
    orderDetails: {
      modalEditST: { id, ticket_number, sand_ticket_type },
      info: {
        order: { order_id },
      },
    },
  } = getState();
  const params = {
    orderId: order_id,
    sandTicketId: id,
    sandTicketType: sand_ticket_type,
    sandTicketNumber: ticket_number,
  };
  order
    .editSandTicket(params)
    .then(response => {
      dispatch(init(order_id));
      swal(response.data.message, '', 'success');
      closeEdit();
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const initSandTicket = () => (dispatch, getState) => {
  const save = stInfo => ({
    type: 'ORDER_DETAILS_INIT_MODAL_EDIT_ST',
    stInfo,
  });
  const state = getState();
  const {
    orderDetails: {
      info: { sandTicketIds },
      sandTicket: { selectedTicket },
    },
  } = state;
  const stInfo = sandTicketIds.find(item => item.id == selectedTicket);
  dispatch(save(stInfo));
};

const setInputValue = (value, name) => ({
  type: 'ORDER_DETAILS_MODAL_EDIT_ST_SET_INPUT',
  name,
  value,
});

// Comments
const initComments = () => (dispatch, getState) => {
  const save = comments => ({
    type: 'ORDER_DETAILS_INIT_COMMENT',
    comments,
  });
  const state = getState();
  const {
    orderDetails: {
      info: {
        order: { order_id },
      },
    },
  } = state;
  order
    .getComments(order_id)
    .then(response => {
      dispatch(save(response.data.data.comments));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const setComment = comment => ({
  type: 'ORDER_DETAILS_SET_ORDER_COMMENT',
  comment,
});

const postComment = () => (dispatch, getState) => {
  const {
    orderDetails: {
      logs: { comment },
      info: {
        order: { order_id },
      },
    },
  } = getState();
  order
    .postComment(order_id, comment)
    .then(response => {
      dispatch(initComments());
      dispatch(setComment(''));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const deleteComment = commentId => dispatch => {
  order
    .deleteComment(commentId)
    .then(response => {
      dispatch(init());
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

// Pricing

const deleteAdditionalPrice = (orderId, priceId) => dispatch => {
  order
    .deleteAdditionalPrice(orderId, priceId)
    .then(response => {
      dispatch(init(orderId));
      queryClient.invalidateQueries({ queryKey: ['orders', Number(orderId), 'price-breakdown']});
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const openDemurrageTicket = item => (dispatch, getState) => {
  const state = getState();
  const {
    orderDetails: {
      info: {
        order: { sandsite_demurrage_reason_id, wellsite_demurrage_reason_id },
      },
    },
  } = state;

  const set = (selectId, item) => ({
    type: 'ORDER_DETAILS_INIT_MODEL_DEMURAGE',
    selectId,
    item,
  });

  dispatch(
    set(
      item.type == 3
        ? wellsite_demurrage_reason_id
        : sandsite_demurrage_reason_id,
      item,
    ),
  );
};

const downloadInvoicePdf = () => (dispatch, getState) => {
  const state = getState();
  const {
    orderDetails: {
      info: {
        order: { order_id },
      },
    },
  } = state;

  order
    .downloadInvoicePdf(order_id)
    .then(response => {
      const file = new Blob([response.data], { type: 'application/pdf' });
      FileSaver.saveAs(file, `order-${order_id}.pdf`);
    })
    .catch(error => {
      if (error.response.status === 400) {
        const errorObject = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)));
        swal(errorObject.message, '', 'error');
      } else {
        swal(error.response.data.message, '', 'error');
      }
    });
};

// Add price modal

const setValueAddPrice = (value, name) => ({
  type: 'ORDER_DETAILS_MODAL_ADD_PRICE_SET_VALUE',
  value,
  name,
});

const clearAddPrice = () => dispatch => {
  dispatch(setValueAddPrice('', 'price'));
  dispatch(setValueAddPrice('', 'comment'));
};

const addAdditionalPrice = closeAdd => (dispatch, getState) => {
  const success = () => ({
    type: 'ORDER_DETAILS_MODAL_ADD_PRICE_SUCCESS',
  });
  const state = getState();
  const {
    orderDetails: {
      info: {
        order: { order_id },
      },
      modalAddPrice: { price, comment },
    },
  } = state;
  order
    .addAdditionalPrice({ id: order_id, comment, price })
    .then(response => {
      dispatch(init(order_id));
      queryClient.invalidateQueries({ queryKey: ['orders', Number(order_id), 'price']});
      queryClient.invalidateQueries({ queryKey: ['orders', Number(order_id), 'price-breakdown']});
      swal(response.data.message, '', 'success');
      closeAdd();
      dispatch(success());
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

// Demurrage

const setDemurrage = selectId => ({
  type: 'ORDER_DETAILS_MODAL_DEMURRAGE_SET_VALUE',
  selectId,
});

const editDemurrage = closeDemurr => (dispatch, getState) => {
  const state = getState();

  const {
    orderDetails: {
      info: {
        order: {
          order_id,
          wellsite_demurrage_reason_id,
          sandsite_demurrage_reason_id,
        },
      },
      demurrage: { itemPriceDemurr, selectId },
    },
  } = state;

  const demurrageIds = {
    sand: sandsite_demurrage_reason_id,
    well: wellsite_demurrage_reason_id,
  };

  const { paramsNewDemurr } = orderDetailsModel;
  const { type } = itemPriceDemurr;
  const params = paramsNewDemurr(type, demurrageIds, selectId, order_id);

  order
    .setDemurrageReasons(params)
    .then(response => {
      dispatch(init(order_id));
      queryClient.invalidateQueries({ queryKey: ['orders'] });
      queryClient.invalidateQueries({ queryKey: ['job-plans'] });
      swal(response.data.message, '', 'success');
      closeDemurr();
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

// Edit price info
const saveInitValueEditPrice = (value, name) => ({
  type: 'ORDER_DETAILS_MODAL_PRICE_SET_INIT_VALUE',
  value,
  name,
});
const setInputValueEditPrice = (value, name) => ({
  type: 'ORDER_DETAILS_MODAL_PRICE_SET_INPUT_VALUE',
  value,
  name,
});
const initInfo = priceModel => ({
  type: 'ORDER_DETAILS_MODAL_PRICE_SET_INIT_MODAL',
  priceModel,
});

const getOrderPriceModel = orderId => dispatch => {
  order
    .getOrderPriceModel(orderId)
    .then(response => {
      dispatch(initInfo(response.data.data));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const getRateTablesList = customerId => dispatch => {
  order
    .getRateTablesList(customerId)
    .then(response => {
      dispatch(saveInitValueEditPrice(response.data.data, 'rateTables'));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const editPriceModel = closeInfo => (dispatch, getState) => {
  const state = getState();
  const {
    orderDetails: {
      priceInfo: { input },
    },
  } = state;
  const {
    orderDetails: {
      info: {
        order: { order_id },
      },
    },
  } = state;
  const {
    percentage,
    formulaType,
    applyCarrierPercentageTo,
    typeProperty,
    rateTableId,
    mileage,
    billableMileage,
    customRate,
  } = input;

  const params = {
    priceModel: {
      carrier: { percentage, formulaType, applyCarrierPercentageTo },
      delivery: {
        type: typeProperty,
        rateTableId,
        mileage,
        billableMileage,
        customRate,
      },
    },
  };

  order
    .editPriceModel(params, order_id)
    .then(response => {
      closeInfo();
      dispatch(init(order_id));
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

// Chat
let database;
const limit = 10;

const initDriverChat = (chatId, driverId) => dispatch => {
  const init = (chatId, driverId) => ({
    type: 'ORDER_DETAILS_INIT_DRIVER_CHAT',
    chatId,
    driverId,
  });
  const saveLastKey = key => ({
    type: 'ORDER_DETAILS_SAVE_LAST_KEY',
    key,
  });
  const setCount = messagesCount => ({
    type: 'ORDER_DETAILS_SET_COUNT',
    messagesCount,
  });

  dispatch(init(chatId, driverId));
};

const saveMessages = message => (dispatch, getState) => {
  const save = messages => ({
    type: 'ORDER_DETAILS_SAVE_MESSAGGES',
    messages,
  });
  const state = getState();
  const messages = [...state.orderDetails.chat.messages];
  messages.push(message);
  dispatch(save(messages));
};

const scrollToBottom = () => {
  setTimeout(() => {
    const block = document.querySelector('.chat__discussion');
    if (block) {
      block.scrollTop = block.scrollHeight;
    }
  }, 0);
};

const destroyChat = () => dispatch => {
};

const sendMessage = () => (dispatch, getState) => {
  const send = () => ({ type: 'ORDER_DETAILS_SEND_MESSAGGES' });
  const user = authService.getUser();
  const state = getState();
  const { chat } = state.orderDetails;
  const { driverId, chatId, text, isFile, file, fileName } = chat;
  const message = {
    senderId: String(user.id),
    senderName: `${user.name} (${userRole[user.role]})`,
    text,
    role: Number(user.role),
    date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
  };
  const pushMessage = () => {
    dispatch(send());
    driver.sendMessage({ driversId: [driverId], message: text }).then(() => { });
  };
  if (!isFile) {
    pushMessage();
  }
};
const setChatInputValue = (value, name) => ({
  type: 'ORDER_DETAILS_SET_CHAT_INPUT_VALUE',
  value,
  name,
});
const setChatFile = file => ({ type: 'ORDER_DETAILS_SET_FILE', file });
const unsetChatFile = () => ({ type: 'ORDER_DETAILS_UNSET_FILE' });
const clearChat = () => ({ type: 'ORDER_DETAILS_CHAT_CLEAR' });

const loadMore = () => (dispatch, getState) => {
};

const initEditFinancials = () => (dispatch, getState) => {
  const success = data => ({
    type: 'ORDER_DETAILS_INIT_FINANCIAL_MODAL',
    data,
  });
  const state = getState();

  const {
    orderDetails: {
      info: {
        order: { billing_status, isDefaultNonBillableReason, nonBillableReason, nonBillableDefaultReason },
      }
    },
  } = state;

  const initData = {
    isNonBillable: billing_status === 24,
    billableCode: isDefaultNonBillableReason ? nonBillableDefaultReason : 0,
    customDescription: !isDefaultNonBillableReason ? nonBillableReason : '',
  };
  dispatch(success(initData));
};

const setValueEditFinancials = (value, name) => ({
  type: 'ORDER_DETAILS_EDIT_FINANCIALS_SET_VALUE',
  value,
  name,
});

const saveEditFinancials = closeEdit => (dispatch, getState) => {
  const success = () => ({
    type: 'ORDER_DETAILS_EDIT_FINANCIAL_CLEAR_EDIT',
  });
  const state = getState();
  const {
    orderDetails: {
      editFinancials: {
        info: { isNonBillable, billableCode, customDescription },
      },
    },
  } = state;

  const {
    orderDetails: {
      info: {
        order: {
          order_id,
        }
      }
    }
  } = state;

  order
    .editNonBillable({ orderId: order_id, isNonBillable, billableCode, customDescription })
    .then(msg => {
      dispatch(success());
      dispatch(init(order_id));
      queryClient.invalidateQueries({ queryKey: ['orders'] })
      swal('Success!', '', 'success');
      closeEdit();
    })
    .catch(error => swal(error?.response?.data?.message, '', 'error'));
};

const actions = {
  init,
  getOrder,
  getBreadcrumb,
  getPriceBreakDown,
  getDemurrage,
  // order header
  approveOrderMethod,
  refreshOrder,
  getTransferred,
  clearTransferred,
  // header -> history modal
  getHistory,
  clearHistory,
  // select driver modal
  setInputValueDriverModal,
  submitSelectDriverModal,
  clearDriverModal,
  // tabs
  changeKey,
  // sand tickets
  getSandTicketsDesign,
  selectTiket,
  clearGetST,
  clearEditST,
  deleteSandTicket,
  // preview sand ticket
  getSandTicket,
  downloadSandTicket,
  // edit sand ticket
  editSandTicket,
  initSandTicket,
  setInputValue,
  // comments
  initComments,
  deleteComment,
  postComment,
  setComment,
  // pricing
  deleteAdditionalPrice,
  openDemurrageTicket,
  downloadInvoicePdf,
  // add price
  setValueAddPrice,
  addAdditionalPrice,
  clearAddPrice,
  // demurrage
  setDemurrage,
  editDemurrage,
  // edit price info
  getOrderPriceModel,
  getRateTablesList,
  setInputValueEditPrice,
  editPriceModel,
  // chat
  initDriverChat,
  saveMessages,
  destroyChat,
  sendMessage,
  setChatInputValue,
  setChatFile,
  unsetChatFile,
  loadMore,
  clearChat,
  // edit financials
  initEditFinancials,
  setValueEditFinancials,
  saveEditFinancials,
};

export default actions;
