import { district } from 'services/district/service';
import { queue } from 'services/queue/service';
import async from 'async';

export const fullDistrictsListRequested = () => async dispatch => {
  dispatch({ type: 'DISTRICTS_REQUESTED' });

  const districts = await district.get().catch(() => dispatch({ type: 'DISTRICTS_REQUEST_FAILED' }));

  return dispatch({
    districts: districts.data.data.district,
    type: 'DISTRICTS_REQUEST_SUCCEEDED',
  });
};

const init = () => dispatch => {
  const callSuccess = (data, array) => ({
    type: 'DISTRICTS_DETAILS_RESPONSE_SUCCESS',
    data,
    array,
  });
  const allLoaded = () => ({ type: 'DISTRICTS_DETAILS_ALL_LOADED' });

  async.parallel(
    [
      async function(callback) {
        const districtsList = await district.get().catch(error => {
          callback(error);
        });
        dispatch(
          callSuccess(districtsList.data.data.district, 'districtsList'),
        );
        callback(null);
      },
      async function(callback) {
        const drivers = await queue.getAvailableDrivers().catch(error => {
          callback(error);
        });
        dispatch(callSuccess(drivers.data.data.availableDrivers, 'drivers'));
        callback(null);
      },
      async function(callback) {
        const orders = await queue.getOrders().catch(error => {
          callback(error);
        });
        dispatch(callSuccess(orders.data.data.orders, 'orders'));
        callback(null);
      },
    ],
    (error) => {
      if (error) {
        swal(error.response.data.message, '', 'error');
      } else {
        dispatch(allLoaded());
      }
    },
  );
};

export default {
  fullDistrictsListRequested,
  init,
};
