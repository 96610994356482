import React, { useMemo, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import moment from 'moment';

import Authorize from 'components/common/Authorize';
import authService from 'services/auth/service';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import DatePicker from 'components/shared/DatePicker';

import { useModifyOrder, useOrder } from 'api/v2/orders';
import { isDateFormatValid } from 'utils/datePickersFunctions';
import OrderDocuments from './Documents';
import { useModifyForm, useOrderPos, useOrderStages } from './hooks';
import {
  formatModifyData,
  isValidData,
  getMaxLoadWeight,
  MAX_SITE_TIME,
  MIN_SITE_TIME,
} from './helpers';

const user = authService.getUser();

const Modify = ({
  orderId,
  openModal,
  turnModal,
  onClose = () => {},
  onSuccess = () => {},
}) => {
  const [isDateAlert, turnDateAlert] = useState(false);
  const { data: order, isLoading: orderLoading } = useOrder({
    orderId: openModal ? orderId : undefined,
  });
  const { mutate: modifyOrder, isPending } = useModifyOrder({ orderId });

  const { data: formData, handlers } = useModifyForm({
    defaultValue: order?.info,
    showModal: openModal,
  });

  const { operationType, jobId, weight, status } = useMemo(() => {
    if (!order) {
      return {};
    }

    return {
      ...order.info,
      operationType: order.info.operation_type,
    };
  }, [order]);

  const stagesList = useOrderStages(jobId);
  const posList = useOrderPos({
    jobId,
    operationType,
  });

  const optionallyFormatTimeToNow = (initialTime, destination) => {
    const formattedTime = initialTime.isValid()
      ? initialTime
      : moment(new Date());
    handlers.valueChange(destination)(formattedTime);
  };

  const handleClose = () => {
    turnModal();
    onClose();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const formattedData = formatModifyData(formData);
    if (isValidData(formattedData)) {
      modifyOrder(formattedData, {
        onSuccess: data => {
          onSuccess(data?.data);
          handleClose();
        },
      });
    }
  };

  return (
    <Modal show={openModal} onHide={handleClose} fullscreen="sm-down">
      <Modal.Header>
        <Modal.Title as="h3">Modify Order #{orderId}</Modal.Title>
      </Modal.Header>
      {orderLoading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <Row>
              <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                <Input
                  label="Customer Order ID"
                  onChange={handlers.valueChange('customerOrderId')}
                  value={formData.customerOrderId}
                  testSelector="order-actions_modify_customer-order-id_input"
                />
              </Col>
              {formData.driverId && (
                <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                  <Input
                    label="Ticket Number"
                    onChange={handlers.valueChange('sandTicketNo')}
                    value={formData.sandTicketNo}
                    required={formData.shouldComplete}
                    testSelector="order-actions_modify_ticket-number_input"
                  />
                </Col>
              )}
              {isDateAlert && (
                <div
                  className="nested-no-outer-gutter"
                  style={{ color: 'red' }}>
                  <p>
                    * Date format should be &apos;YYYY-MM-DD hh:mm AM/PM&apos;
                  </p>
                </div>
              )}
              {formData.driverId && (
                <>
                  <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                    <DatePicker
                      label="Order Accepted Time"
                      value={formData.orderAcceptedAt}
                      onChange={e => {
                        isDateFormatValid(e, turnDateAlert);
                        handlers.valueChange('orderAcceptedAt')(e);
                      }}
                      dateFormat="YYYY-MM-DD"
                      timeFormat="hh:mm A"
                      testSelector="order-actions-modify_load-accepted-time_dp"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                    <div />
                  </Col>
                  <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                    <DatePicker
                      label="Origin Arrival Time"
                      value={formData.loadArrivalTime}
                      onChange={e => {
                        isDateFormatValid(e, turnDateAlert);
                        handlers.valueChange('loadArrivalTime')(e);
                      }}
                      dateFormat="YYYY-MM-DD"
                      timeFormat="hh:mm A"
                      testSelector="order-actions-modify_load-arrival-time_dp"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                    <DatePicker
                      label="Origin Depart Time"
                      value={formData.loadDepartureTime}
                      onChange={e => {
                        isDateFormatValid(e, turnDateAlert);
                        handlers.valueChange('loadDepartureTime')(e);
                      }}
                      dateFormat="YYYY-MM-DD"
                      timeFormat="hh:mm A"
                      testSelector="order-actions-modify_load-depart-time_dp"
                    />
                  </Col>
                </>
              )}

              {formData.driverId && formData.isStageSiteOnOrder && (
                <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                  <DatePicker
                    label="Staging Arrival Time"
                    value={formData.stageArrivalTime}
                    onChange={e => {
                      isDateFormatValid(e, turnDateAlert);
                      handlers.valueChange('stageArrivalTime')(e);
                    }}
                    dateFormat="YYYY-MM-DD"
                    timeFormat="hh:mm A"
                    testSelector="order-actions-modify_staging-arrival-time_dp"
                  />
                </Col>
              )}
              {formData.driverId && formData.isStageSiteOnOrder && (
                <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                  <DatePicker
                    label="Staging Depart"
                    value={formData.stageDepartureTime}
                    onChange={e => {
                      isDateFormatValid(e, turnDateAlert);
                      handlers.valueChange('stageDepartureTime')(e);
                    }}
                    dateFormat="YYYY-MM-DD"
                    timeFormat="hh:mm A"
                    testSelector="order-actions-modify_staging-depart-time_dp"
                  />
                </Col>
              )}
              {formData.driverId && (
                <>
                  <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                    <DatePicker
                      label="Destination Arrival Time"
                      value={formData.wellArrivalTime}
                      onChange={e => {
                        isDateFormatValid(e, turnDateAlert);
                        handlers.valueChange('wellArrivalTime')(e);
                      }}
                      dateFormat="YYYY-MM-DD"
                      timeFormat="hh:mm A"
                      testSelector="order-actions-modify_well-arrival-time_dp"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                    <DatePicker
                      label="Destination Depart Time"
                      value={formData.wellDepartureTime}
                      onFocus={() =>
                        optionallyFormatTimeToNow(
                          formData.wellDepartureTime,
                          'wellDepartureTime',
                        )
                      }
                      onChange={e => {
                        isDateFormatValid(e, turnDateAlert);
                        handlers.valueChange('wellDepartureTime')(e);
                      }}
                      dateFormat="YYYY-MM-DD"
                      timeFormat="hh:mm A"
                      testSelector="order-actions-modify_well-depart-time_dp"
                    />
                  </Col>
                </>
              )}
              <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                <Input
                  label="Truck"
                  value={formData.truck}
                  onChange={handlers.valueChange('truck')}
                  testSelector="order-actions_modify_truck_input"
                />
              </Col>
              <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                <Input
                  label="Trailer"
                  onChange={handlers.valueChange('trailer_number')}
                  value={formData.trailerNumber}
                  testSelector="order-actions_modify_trailer_input"
                />
              </Col>
              <Authorize forbidden={['JOB_COORDINATOR']}>
                <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                  <Input
                    type="number"
                    label="Mileage"
                    min="0"
                    onChange={handlers.valueChange('mileage')}
                    value={formData.mileage}
                    required={formData.shouldComplete}
                    testSelector="order-actions_modify_mileage_input"
                  />
                </Col>
              </Authorize>
              {formData.operationType !== 12 && (
                <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                  <Input
                    label="Silo/King/Comp"
                    onChange={handlers.valueChange('kingComp')}
                    value={formData.kingComp}
                    testSelector="order-actions_modify_king-comp_input"
                  />
                </Col>
              )}
              {formData.driverId && (
                <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                  <Input
                    type="number"
                    label="Quantity"
                    min={!formData.shouldComplete && status !== 4 ? 0 : 100}
                    // max={weight}
                    max={getMaxLoadWeight(user, order?.info)}
                    step={1}
                    onChange={handlers.valueChange('driverWeight')}
                    value={formData.driverWeight || weight}
                    required={formData.shouldComplete}
                    testSelector="order-actions_modify_load-weight_input"
                  />
                </Col>
              )}
              {operationType !== 12 && (
                <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                  <Select
                    placeholder="Stage"
                    onChange={handlers.valueChange('stage')}
                    value={stagesList.find(
                      item => item.value === formData.stage,
                    )}
                    options={stagesList}
                    required
                    testSelector="order-actions_modify_stage_select"
                  />
                </Col>
              )}

              <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                <Select
                  placeholder={
                    operationType === 12
                      ? 'Destination'
                      : 'PO / Commodity / Location'
                  }
                  onChange={handlers.valueChange('po')}
                  value={posList.find(item => item.value === formData.po)}
                  options={posList}
                  isOptionDisabled={option => option.disabled}
                  required={formData.shouldComplete}
                  testSelector="order-actions_modify_po_select"
                />
              </Col>
              {formData.driverId && (
                <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                  <Input
                    type="checkbox"
                    testSelector="order-actions_modify_truck-down"
                    onChange={handlers.valueChange('truckDown')}
                    isChecked={formData.truckDown}
                    label="Truck Down"
                    disabled={formData.status === 4}
                  />
                </Col>
              )}
              {![0, 1].includes(formData.status) && (
                <Row>
                  <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                    <div
                      id="vertical-timeline2"
                      className="vertical-container dark-timeline">
                      <div className="vertical-timeline-block">
                        <div className="vertical-timeline-icon navy-bg">
                          <Icon icon="truck" />
                        </div>
                        <div className="vertical-timeline-content">
                          <span>
                            <b>Driver was at Origin</b>
                          </span>
                          <span className="vertical-date">
                            <h1
                              className={`font-bold ${
                                formData.sandSiteTime > MAX_SITE_TIME
                                  ? 'text-warning'
                                  : 'text-navy'
                              }`}>
                              {formData.sandSiteTime} min
                            </h1>
                            {formData.sandSiteTime < MIN_SITE_TIME && (
                              <div className="text-danger">Incorrect time</div>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                    <div
                      id="vertical-timeline2"
                      className="vertical-container dark-timeline">
                      <div className="vertical-timeline-block">
                        <div className="vertical-timeline-icon navy-bg">
                          <Icon icon="truck" />
                        </div>
                        <div className="vertical-timeline-content">
                          <span>
                            <b>Driver was at Destination</b>
                          </span>
                          <span className="vertical-date">
                            <h1
                              className={`font-bold ${
                                formData.wellsiteTime > MAX_SITE_TIME
                                  ? 'text-warning'
                                  : 'text-navy'
                              }`}>
                              {formData.wellsiteTime} min
                            </h1>
                            {formData.wellsiteTime < MIN_SITE_TIME && (
                              <div className="text-danger">Incorrect time</div>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              <OrderDocuments orderId={orderId} />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleClose}
              colour="white"
              testSelector="order-actions_modify_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              onClick={() => handlers.valueChange('shouldComplete')(false)}
              disabled={isPending || isDateAlert}
              testSelector="order-actions_modify_save_btn">
              Save
            </Button>
            {status !== 4 && formData.driverId && (
              <Button
                type="submit"
                onClick={() => handlers.valueChange('shouldComplete')(true)}
                colour="green"
                disabled={isPending || isDateAlert}
                testSelector="order-actions_modify_complete_btn">
                Complete
              </Button>
            )}
          </Modal.Footer>
        </form>
      )}
    </Modal>
  );
};

export default Modify;
