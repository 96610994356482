import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';

import targetValue from 'utils/targetValue';
import { useEditDistrict } from 'api/v2/districts';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Authorize from 'components/common/Authorize';

const EditDistrict = ({ district, showModal, closeModal }) => {
  const { mutate: editDistrictDetails } = useEditDistrict(district.id);
  const [inputValues, _setInputValue] = useState({});
  const clear = () => _setInputValue({});

  const setInputValue = (e, name) =>
    _setInputValue(prev => ({ ...prev, [name]: e }));

  const submitEdit = (e, hideModal) => {
    e.preventDefault();
    editDistrictDetails(
      {
        ...district,
        isCarrierRatioEnabled: Number(inputValues.isCarrierRatioEnabled),
        isManualDispatchEnabled: Number(inputValues.isManualDispatchEnabled),
        name: inputValues.name,
        districtDetails: inputValues.districtDetails,
        activeTrucks: Number(inputValues.activeTrucks),
      },
      {
        onSuccess: hideModal
      },
    );
  }

  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    setInputValue(district.district_details, 'districtDetails');
    setInputValue(
      district.isCarrierRatioEnabled || false,
      'isCarrierRatioEnabled',
    );
    setInputValue(district.name, 'name');
    setInputValue(
      district.isManualDispatchEnabled || false,
      'isManualDispatchEnabled',
    );
    setInputValue(district.activeTrucks || 0, 'activeTrucks');
    return () => {
      clear();
    };
  }, []);

  // Determine if save is disabled
  useEffect(() => {
    let disabled = false;
    if (!inputValues.name?.trim().length || !inputValues.districtDetails?.trim().length) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  }, [inputValues]);

  return (
    <Modal className="modal-container" show={showModal} onHide={closeModal}>
      <Modal.Header>
        <h3>Edit District</h3>
      </Modal.Header>
      <form onSubmit={e => submitEdit(e, closeModal)}>
        <Modal.Body className="form-group">
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={e => setInputValue(targetValue(e), 'name')}
                value={inputValues.name}
                label="Name"
                required
                testSelector="district-details_edit_name_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={e => setInputValue(targetValue(e), 'districtDetails')}
                value={inputValues.districtDetails}
                label="District details"
                required
                testSelector="district-details_edit_details_input"
              />
            </div>
          </Row>
          <Authorize
            {...{
              abilityPermissions: [
                {
                  resource: 'Districts',
                  permissions: ['update'],
                },
              ],
              itemAccessGranted: district.accessLevel === 'owner',
            }}>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="checkbox"
                  testSelector="district-details_edit_carrier-ratio_input"
                  isChecked={inputValues.isCarrierRatioEnabled}
                  onChange={e => setInputValue(targetValue(e), 'isCarrierRatioEnabled')}
                  label="Carrier Ratio"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="checkbox"
                  testSelector="district-details_edit_manual-dispatch_input"
                  isChecked={inputValues.isManualDispatchEnabled}
                  onChange={e => setInputValue(targetValue(e), 'isManualDispatchEnabled')}
                  label="Manual Dispatch"
                />
              </div>
            </Row>
          </Authorize>
          {inputValues.isCarrierRatioEnabled && (
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e => setInputValue(targetValue(e), 'activeTrucks')}
                  value={inputValues.activeTrucks}
                  label="Active Trucks"
                  min={0}
                  max={100}
                  step={1}
                  disabled
                  testSelector="manage-database_districts_add-edit_activeTrucks_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6" />
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={closeModal}
            colour="white"
            testSelector="district-details_edit_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={saveDisabled}
            testSelector="district-details_edit_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditDistrict;
