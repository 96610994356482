export default {
  0: 'Open', // <- Pending
  2: 'Approved', // <- Confirmed
  4: 'Contested', // <- Disputed
  20: 'Ready to Bill',
  21: 'Billed',
  22: 'Paid',
  23: 'Settled',
  24: 'Non Billable',
};

export const orderStatus = {
  0: 'Open',
  1: 'Assigned',
  2: 'Accepted',
  3: 'En Route to Destination',
  4: 'Completed',
  5: 'Entered Origin',
  6: 'Entered Destination',
  7: 'En Route to Staging',
  8: 'Entered Staging'
}