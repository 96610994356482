import swal from 'bootstrap-sweetalert';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import {
  get,
  getPaginated,
  getAvailable,
  getRating,
  getOsVersions,
  getAppVersions,
  getDistricts,
  changeDistricts,
  changeRating,
  changePercentage,
  logOut,
  deleteDriver,
  restore,
  free,
  sendMessage,
  pushNotification,
  create,
  getProfile,
  getCertificates,
  edit,
  editTruck,
  editAllDrivers,
  downloadRejectionReport
} from './queries';

export const useDrivers = () =>
  useQuery({
    queryKey: ['drivers'],
    queryFn: get,
    placeholderData: [],
  });

export const usePaginatedDrivers = params =>
  useQuery({
    queryKey: ['drivers', params],
    queryFn: () => getPaginated(params),
  });

export const useDriverOsVersions = () =>
  useQuery({
    queryKey: ['drivers', 'os-versions'],
    queryFn: getOsVersions,
  });

export const useDriverAppVersions = () =>
  useQuery({
    queryKey: ['drivers', 'app-versions'],
    queryFn: getAppVersions,
  });

export const useChangeDriverDistricts = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: changeDistricts,
    onSuccess: res => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries({
        queryKey: ['drivers'],
      });
    },
    onError: err => {
      swal(err.response.data.message, '', 'error');
    },
  });
};

export const useDriverDistricts = ({ driverId }) =>
  useQuery({
    queryKey: ['drivers', Number(driverId), 'districts'],
    queryFn: () => getDistricts({ driverId }),
    enabled: !!driverId
  });

export const useDriverRating = ({ driverId }) =>
  useQuery({
    queryKey: ['drivers', Number(driverId), 'rating'],
    queryFn: () => getRating({ driverId }),
    enabled: !!driverId
  });

export const useChangeDriverRating = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: changeRating,
    onSuccess: res => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries({
        queryKey: ['drivers'],
      });
    },
    onError: err => {
      swal(err.response.data.message, '', 'error');
    },
  });
};

export const useChangeDriverPercentage = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: changePercentage,
    onSuccess: res => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries({
        queryKey: ['drivers']
      });
    },
    onError: err => {
      swal(err.response.data.message, '', 'error');
    },
  });
};

export const useLogOutDriver = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: logOut,
    onSuccess: res => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries({
        queryKey: ['drivers'],
      });
    },
    onError: err => {
      swal(err.response.data.message, '', 'error');
    },
  });
};

export const useDeleteDriver = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteDriver,
    onSuccess: res => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries({
        queryKey: ['drivers'],
      });
    },
    onError: err => {
      swal(err.response.data.message, '', 'error');
    },
  });
};

export const useRestoreDriver = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: restore,
    onSuccess: res => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries({
        queryKey: ['drivers'],
      });
    },
    onError: err => {
      swal(err.response.data.message, '', 'error');
    },
  });
};

export const useFreeDriver = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: free,
    onSuccess: res => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries({
        queryKey: ['drivers'],
      });
    },
    onError: err => {
      swal(err.response.data.message, 'Failed to free driver', 'error');
    },
  });
};

export const useSendDriverMessage = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: params => sendMessage(params),
    onSuccess: res => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries({
        queryKey: ['drivers'],
      });
    },
    onError: err => {
      swal(err.response.data.message, '', 'error');
    },
  });
};

export const useSendDriverPushNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: params => pushNotification(params),
    onSuccess: res => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries({
        queryKey: ['drivers'],
      });
    },
    onError: err => {
      swal(err.response.data.message, '', 'error');
    },
  });
};

export const useCreateDriver = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: create,
    onSuccess: res => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries({
        queryKey: ['drivers'],
      });
    },
    onError: err => {
      swal(err.response.data.message, '', 'error');
    },
  });
};

export const useDriverProfile = ({ driverId }) =>
  useQuery({
    queryKey: ['drivers', Number(driverId)],
    queryFn: () => getProfile({ driverId }),
  });

export const useDriverCertificates = ({ id }) =>
  useQuery({
    queryKey: ['drivers', Number(id)],
    queryFn: () => getCertificates({ id }),
  });

export const useEditDriver = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: params => edit(params),
    onSuccess: res => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries({
        queryKey: ['drivers'],
      });
    },
    onError: err => {
      swal(err.response.data.message, '', 'error');
    },
  });
};

export const useEditDriverTruck = ({ id }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editTruck,
    onSuccess: res => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries({
        queryKey: ['drivers', Number(id)],
      });
    },
    onError: err => {
      swal(err.response.data.message, '', 'error');
    },
  });
};

export const useEditAllDrivers = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: params => editAllDrivers(params),
    onSuccess: res => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries({
        queryKey: ['drivers'],
      });
    },
    onError: err => {
      swal(err.response.data.message, '', 'error');
    },
  });
};

export const useDownloadDriverRejectionReport = () =>
  useMutation({
    mutationFn: downloadRejectionReport,
    onError: err => {
      swal(err.response.data.message, '', 'error');
    }
  });

export const useAvailableDrivers = () =>
  useQuery({
    queryKey: ['drivers', 'available'],
    queryFn: getAvailable,
  });
