import React, { useEffect } from 'react';

import Pagination from 'components/shared/Pagination';

const ReduxPagination = ({ currentPage, totalPages, clearState, setPage }) => {
  useEffect(() => clearState, []);
  
  // useEffect(() => {
  //   setPage(currentPage);
  // }, [currentPage]);

  return (
    <Pagination
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={({ currentPage }) => setPage(currentPage)}
    />
  );
};

Pagination.defaultProps = {
  currentPage: 1,
  totalPages: 1,
  clearState: () => null,
  setPage: () => null,
};

export default ReduxPagination;
