import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getDriverContainers, getContainers } from './queries';

export const useContainers = (params) => useQuery({
  queryKey: ['containers', params],
  queryFn: () => getContainers(params),
  // placeholderData: { totalPages: 0, equipments: [] },
  placeholderData: keepPreviousData
});

export const useDriverContainers = (driverId, districtIds) => useQuery({
    queryKey: ['containers', driverId, districtIds],
    queryFn: () => getDriverContainers(driverId, districtIds),
    placeholderData: [],
    enabled: !!driverId && !!districtIds.length
  })
