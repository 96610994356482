import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import { getNonBillingReasons, addReason, editReason } from './queries';

/**
 * @param {{
 *  shown?: 0 | 1 | null
 * }} params
 */
export const useNonBillableReasons = ({ shown } = {}) =>
  useQuery({
    queryKey: ['nonBillingReasons', { shown }],
    queryFn: () => getNonBillingReasons({ shown }),
    placeholderData: [],
  });

export const useAddNonBillableReasons = (options = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addReason,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['nonBillingReasons'] });
      swal(`Successfully added Nonbillable Reason!`, '', 'success');
    },
    onError: err => {
      swal(
        err.data.message || 'Failed to add Nonbillable Reason.',
        '',
        'error',
      );
    },
    ...options,
  });
};

export const useEditNonBillableReasons = (options = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: editReason,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['nonBillingReasons'] });
      swal(`Successfully edited Nonbilling Reason!`, '', 'success');
    },
    onError: err => {
      swal(
        err.data.message || 'Failed to edit Nonbilling Reason.',
        '',
        'error',
      );
    },
    ...options,
  });
};
