import { client } from 'api/client';

/**
 * @param {{
 *  shown?: 0 | 1 | null
 * }} params
 * @returns {Promise<Array>}
 */
export const getNonBillingReasons = async ({ shown } = {}) => {
  const params = {};
  if (shown !== undefined) {
    params.shown = shown;
  }
  const { data } = await client.get('/v2/non-billable/reasons', {
    params,
  });
  return data.data;
};

/**
 * @param {Object} reason
 * @param {string} reason.description
 * @param {number} reason.shown
 * @returns {Promise<Object>}
 */
export const addReason = async reason => {
  const { data } = await client.post('/v2/non-billable', reason);
  return data;
};

/**
 * @param {Object} reason
 * @param {string} reason.description
 * @param {number} reason.shown
 * @param {number} reason.id
 * @returns {Promise<Object>}
 */
export const editReason = async reason => {
  const { data } = await client.patch(`v2/non-billable/${reason.id}`, reason);
  return data;
};
