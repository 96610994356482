import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Row } from 'react-bootstrap';
import targetValue from 'utils/targetValue';
import Button from 'components/shared/Button';
import Input, { Textarea } from 'components/shared/Input';
import {
  useAddNonBillableReasons,
  useEditNonBillableReasons,
} from 'api/v2/orders/nonbillable-reasons';

const AddEditModal = ({ showUpdate, closeUpdate, modalValue }) => {
  const { mutate: addReason } = useAddNonBillableReasons();
  const { mutate: editReason } = useEditNonBillableReasons();

  const [formValues, setFormValues] = useState({
    shown: false,
    description: '',
  });

  const handleChange = (e, name) => {
    setFormValues({ ...formValues, [name]: targetValue(e) });
  };

  const saveDisabled = useMemo(
    () => !(formValues.description ?? '').trim().length,
    [formValues],
  );

  const handleClose = () => {
    setFormValues({
      shown: false,
      description: '',
    });
    closeUpdate();
  };

  const handleSubmit = async (e, data) => {
    e.preventDefault();
    const transformedData = {
      ...data,
      shown: data.shown ? 1 : 0,
    };

    switch (modalValue.status) {
      case 'add':
        addReason(transformedData, { onSettled: handleClose });
        break;
      case 'edit':
        editReason(transformedData, { onSettled: handleClose });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (modalValue && !(modalValue?.status === 'add')) {
      setFormValues(modalValue);
    } else {
      setFormValues({
        shown: false,
        description: '',
      });
    }
  }, [modalValue]);

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showUpdate} onHide={closeUpdate}>
        <form
          className="m-t"
          role="form"
          onSubmit={e => handleSubmit(e, formValues)}>
          <Modal.Header>
            <h3>
              {modalValue.status === 'add'
                ? 'Add NonBillable Reason'
                : 'Edit NonBillable Reason'}
            </h3>
          </Modal.Header>
          <Modal.Body className="form-group">
            <Row>
              <div className="form-group has-feedback col-md-12">
                <Input
                  onChange={e => {
                    handleChange(e, 'shown');
                  }}
                  isChecked={formValues.shown}
                  label="Shown in Modal"
                  type="checkbox"
                  testSelector="order_details_edit_financials_billable_input"
                />
              </div>
              <div className="form-group has-feedback col-md-12">
                <Textarea
                  label="Custom description"
                  onChange={e => handleChange(e.target.value, 'description')}
                  value={formValues.description}
                  required
                  maxLength={255}
                  testSelector="edit-nonBillable_description_input"
                />
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleClose}
              colour="white"
              testSelector="database_nonBillable_add-edit_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={saveDisabled}
              testSelector="database_nonBillable_add-edit_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default AddEditModal;
