import axios from 'axios';

export const equipment = {
  get() {
    return axios.get(`v2/lookup/equipments`, {});
  },
  getEquipment(body = {}) {
    if (body.wellSiteId === undefined) {
      body.wellSiteId = 0;
    }
    if (body.sandSiteId === undefined) {
      body.sandSiteId = 0;
    }
    return axios.get(`v2/customer/${body.id}/containers?wellSiteId=${body.wellSiteId
      }&sandsiteId=${body.sandSiteId}
		&page=${body.page}&limit=${body.limit}&type=${body.type
      }&equipmentNumber=${body.equipmentNumber || ''}`);
  },
  getEquipmentWithFilters(body = {}) {
    if (body.wellSiteId === undefined) {
      body.wellSiteId = 0;
    }
    if (body.sandSiteId === undefined) {
      body.sandSiteId = 0;
    }
    return axios.get(`v2/customer/containers?wellSiteId=${body.wellSiteId
      }&sandsiteId=${body.sandSiteId}
		&page=${body.page}&limit=${body.limit}&type=${body.type
      }&equipmentNumber=${body.equipmentNumber || ''}&customerId=${body.id || ''}`);
  },
  updateStatus({
    customerId,
    equipmentId,
    sandTypeId,
    sandVendorId,
    status,
    weight,
  }) {
    return axios.put(
      `v2/customer/${customerId}/containers/${equipmentId}/status`,
      {
        sandTypeId,
        sandVendorId,
        status,
        weight,
      },
    );
  },
  getHistory(customerId, containerId) {
    return axios.get(
      `v2/customer/${customerId}/containers/${containerId}/history?limit=10`,
    );
  },
  updateLocation({
    customerId,
    equipmentId,
    locationId,
    locationType,
    comment,
  }) {
    return axios.put(
      `v2/customer/${customerId}/containers/${equipmentId}/location`,
      {
        locationId,
        locationType,
        comment,
      },
    );
  },
  addTrailer({ customerId, vendorId, trailerNumber, wellSiteId }) {
    return axios.post(`v2/customer/${customerId}/containers`, {
      locationId: wellSiteId,
      containerVendorId: vendorId,
      equipmentNumber: trailerNumber,
      equipmentType: 1,
      locationType: 2,
      status: 3,
    });
  },
  editContainer({
    containerId,
    containerNumber,
    containerVendorId,
    customerId,
    updateVendor,
    homeLocation
  }) {
    return axios.put(`v2/container/${containerId}`, {
      containerId,
      containerNumber,
      containerVendorId,
      updateVendor,
      customerId,
      homeLocation,
    });
  },
  deleteContainer(containerId) {
    return axios.delete(`v2/container/${containerId}`);
  },
  addContainer({
    customerId,
    vendorId,
    trailerNumber,
    bulkUpload,
    containerNumbers,
    wellSiteId,
    homeLocationId
  }) {
    return axios.post(`v2/customer/${customerId}/containers`, {
      locationId: wellSiteId,
      containerVendorId: vendorId,
      equipmentNumber: trailerNumber,
      bulkUpload,
      containerNumbers,
      homeLocationId,
      equipmentType: 2,
      locationType: 2,
      status: 3,
    });
  },
};
