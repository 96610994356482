import axios from 'axios';

export const order = {
  get(data, cancelToken = {}) {
    return axios.post('v2/order/search', data, { cancelToken: cancelToken.token });
  },
  getTickets(orderId) {
    return axios.get(`v2/order/${orderId}/tickets`);
  },
  downloadAllTickets(orderId) {
    return axios({
      method: 'get',
      url: `/v2/order/${orderId}/sand-tickets/download`,
      responseType: 'arraybuffer',
    });
  },
  billing: {
    get(data) {
      return axios.post('v2/billing/search', data);
    },
    confirm(data) {
      return axios.post('v2/billing/confirm', data);
    },
    status(data) {
      return axios.post('v2/billing/status', data);
    },
    invoice(data) {
      return axios.post('v2/billing/invoice/create', data);
    },
    dispute(data) {
      return axios.post('v2/billing/orders/dispute', data);
    },
    approve(data) {
      return axios.post('v2/billing/orders/approve', data);
    },
    getDisputeReasons(data) {
      return axios.get('/v2/lookup/dispute-reasons', data);
    },
    createInvoiceFromOrders(data) {
      return axios.post('/invoice', data);
    },
  },
  getCount(data) {
    return axios.post('v2/order/search?countOnly=true', data);
  },
  getOrderPriceModel(jobId) {
    return axios.get(`v2/order/${jobId}/price-model`, {});
  },
  getRateTablesList(customerId) {
    return axios.post(`v2/customer/${customerId}/getRateTables`, {});
  },
  breadcrumb(orderId) {
    return axios.get(`v2/order/${orderId}/bread-crumb`, {});
  },
  pricebreakdown(orderId) {
    return axios.get(`v2/order/${orderId}/price-breakdown`, {});
  },
  priceBreakdownV2(orderId) {
    return axios.get(`v2/order/${orderId}/price-breakdown`, {});
  },
  getComments(orderId) {
    return axios.get(`v2/order/${orderId}/comment`, {});
  },
  postComment(orderId, comment) {
    return axios.post(`v2/order/${orderId}/comment`, {
      comment,
    });
  },
  deleteComment(orderId) {
    return axios.post(`v2/order/comment/${orderId}/delete`, {});
  },
  addAdditionalPrice(data) {
    return axios.post(`v2/order/${data.id}/additional-price`, data);
  },
  addOrdersToExportHistory(data) {
    return axios.post('v2/export/addOrderrsToExportHistory', data);
  },
  deleteAdditionalPrice(orderId, priceId) {
    return axios.delete(
      `v2/order/${orderId}/additional-price/${priceId}`,
      {},
    );
  },
  getTicketTypes(orderId) {
    return axios.get(`v2/job/${orderId}/sand-ticket-types`);
  },
  downloadInvoicePdf(orderId) {
    return axios({
      method: 'post',
      url: `v2/order/${orderId}/invoice.pdf`,
      headers: { 'Content-Type': 'application/pdf' },
      responseType: 'arraybuffer',
    });
  },
  getSandTicket(orderId, ticketId) {
    return axios({
      method: 'post',
      url: `v2/order/${orderId}/sandTicket/${ticketId}`,
      headers: { 'Content-Type': 'image/*' },
      responseType: 'arraybuffer',
    });
  },
  editSandTicket(params) {
    return axios.put(`v2/job-manager/orders/${params.orderId}/sand-tickets/${params.sandTicketId}`, params);
  },
  deleteSandTicket(orderId, ticketId) {
    return axios.delete(`v2/order/${orderId}/sand-tickets/${ticketId}`);
  },
  modify(data) {
    return axios.put(`/v2/order/${data.orderId}`, data);
  },
  getOrdersToJobId(jobId, selected) {
    return axios.post('v2/order/search', {
      jobId,
      statuses: selected,
    });
  },
  getDemurrageReasons() {
    return axios.get('v2/job-manager/demurrage-reason');
  },
  setDemurrageReasons(params) {
    return axios.put(`v2/order/${params.orderId}/demurrage-reason`, params);
  },
  editPriceModel(params, orderId) {
    return axios.put(`v2/order/${orderId}/price-model`, params);
  },
  confirmAppointment(id) {
    return axios.put(`v2/order/${id}/appointment/confirm`, {});
  },
  appointmentUpdate(params) {
    return axios.put(`v2/order/${params.id}/appointment`, params);
  },
  changeLine(data) {
    return axios.put(`v2/order/${data.orderId}/line/${data.lineId}`, {});
  },
  confirm(orderId) {
    return axios.post(`v2/order/${orderId}/confirm`, {});
  },
  review(orderId) {
    return axios.post(`v2/order/${orderId}/review`, {});
  },
  containers(orderId) {
    return axios.get(`v2/order/${orderId}/containers`, {});
  },
  getSandTicketTypesDesign(orderId) {
    return axios.get(`v2/order/${orderId}/sand-ticket-types`);
  },
  getJobsForDiversion(orderId) {
    return axios.get(`v2/order/${orderId}/jobs-for-diversion`, {});
  },
  divertOrder(data) {
    return axios.put(`v2/order/${data.orderId}/divert-order`, data);
  },
  releaseDriverFromOrder(data) {
    return axios.post(`v2/order/${data.orderId}/release-driver`, data);
  },
  callDriverToWell(data) {
    return axios.post('/v2/driver/call-to-well', data);
  },
  getHistory(orderId) {
    return axios.get(`v2/order/${orderId}/review-log`, {});
  },
  assignDriver(data) {
    return axios.post(`v2/order/${data.orderId}/assign-driver`, data);
  },
  assign(data) {
    return axios.post(
      `v2/order/${data.orderId}/driver/${data.driverId}/assign`,
      data,
    );
  },
  transfer: {
    put(data) {
      return axios.put(`v2/order/${data.orderId}/transfer`, data);
    },
    get(data) {
      return axios.get(`v2/order/${data}/transfer`);
    },
  },
  delete(data) {
    return axios.post('v2/order/remove', data);
  },
  revive(data) {
    return axios.put(`v2/order/${data.orderId}/revive`, {});
  },
  reroute(id, data = {}) {
    return axios.post(`v2/order/${id}/reroute`, data);
  },
  requeue(data) {
    return axios.post(`v2/order/${data.orderId}/start`, data);
  },
  editNonBillable({ orderId, isNonBillable, billableCode, customDescription }){
    const payload ={
      nonBillable: isNonBillable,
      ...(customDescription ? { description: customDescription } : { billableCode })
    }
    return axios.put(`v2/order/${orderId}/nonbillable`, payload);
  },
};
