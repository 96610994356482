import React, { useState } from 'react';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import Loader from 'components/shared/Loader';
import { authorize } from 'components/common/Authorize';

import { useNonBillableReasons } from 'api/v2/orders/nonbillable-reasons';
import AddEditModal from './AddEditModal';

const NonBillable = () => {
  const { data: reasons, isLoading } = useNonBillableReasons();
  const [showUpdate, turnShowUpdate] = useState(false);
  const [modalValue, setModalValue] = useState({ status: 'add' });
  const openUpdate = (status, value) => {
    const transformedValue = value
      ? {
          id: value?.id || '',
          description: value?.description || '',
          shown: Boolean(value?.shown_in_modal),
        }
      : { status: 'add' };
    setModalValue({
      ...transformedValue,
      status,
    });
    turnShowUpdate(true);
  };

  if (isLoading) {
    return <Loader />;
  }

  const renderReason = item => (
    <div key={`key${item.id}`} className="tree-node tree-node-content">
      <span
        className="tree-node-content-name"
        style={{ color: '#ffffff', display: 'flex', alignItems: 'center' }}>
        <strong className="truncate-text-100">{` ${item.id} | ${
          item.description
        } `}</strong>
        {authorize({
          abilityPermissions: [
            { resource: 'Database', permissions: ['update'] },
          ],
        }) ||
        authorize({
          abilityPermissions: [
            { resource: 'Carrier-documents', permissions: ['update'] },
          ],
        }) ? (
          <Button
            onClick={() => openUpdate('edit', item)}
            theme="small"
            testSelector="database_nonBillable_edit_btn">
            <Icon icon="pencil" />
          </Button>
        ) : null}
      </span>
      <div>{` ${item.description} `}</div>
    </div>
  );

  return (
    <div className="panel-body">
      <div className="wrapper-content">
        <div className="row">
          <div>
            <div className="ibox">
              <div className="ibox-content">
                {authorize({
                  abilityPermissions: [
                    { resource: 'Database', permissions: ['update'] },
                  ],
                }) ||
                authorize({
                  abilityPermissions: [
                    { resource: 'Carrier-documents', permissions: ['update'] },
                  ],
                }) ? (
                  <Button
                    onClick={() => openUpdate('add')}
                    inverse
                    className="float-end"
                    testSelector="database_nonBillable_add_btn">
                    <Icon className="icon--margin-right" icon="plus-square" />
                    Add NonBillable Reason
                  </Button>
                ) : null}
                {reasons.map(renderReason)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddEditModal
        showUpdate={showUpdate}
        modalValue={modalValue}
        closeUpdate={() => turnShowUpdate(false)}
      />
    </div>
  );
};

export default NonBillable;
