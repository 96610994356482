import { useMutation, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';

import { applyPurchaseOrderMileage } from './queries';

export const useApplyMileageToPO = ({ poNo }) => {
  const queryClient = useQueryClient();

  return useMutation({
    queryKey: ['order-apply-po-milage'],
    mutationFn: poOrders => applyPurchaseOrderMileage({ poOrders }), 
    onSuccess: () => {
      swal('Successfully Applied Mileage', '', 'success');
      queryClient.invalidateQueries({ queryKey: ['purchase-order-orders', poNo] });
    },
    onError: error => {
      return swal('Unable to Update PO', error.response?.data?.message, 'error');
    }
  });
};
