import React, { useState } from 'react';

import Authorize from 'components/common/Authorize';
import Button from 'components/shared/Button';
import Header from 'components/common/Header';
import Icon from 'components/shared/Icon';

import AddTrailer from './AddTrailerModal';
import BulkUpload from './BulkUploadModal';

const EquipmentsHeader = ({ isContainers }) => {
  const [showAddTrailer, turnModal] = useState(false);
  const [showBulkUpload, turnBulkUpload] = useState(false);

  return (
    <Header title={isContainers ? 'Container Details' : 'Trailer Details'}>
      {isContainers && (
        <Authorize
          abilityPermissions={[
            { resource: 'Containers', permissions: ['create'] },
          ]}>
          <Button
            inverse
            className="float-end"
            onClick={() => turnBulkUpload(true)}>
            <Icon icon="upload" />
          </Button>
        </Authorize>
      )}
      {isContainers ? (
        <Authorize
          abilityPermissions={[
            { resource: 'Containers', permissions: ['create'] },
          ]}>
          <Button
            onClick={() => turnModal(true)}
            inverse
            testSelector="equipments_header_add-container_btn"
            className="float-end">
            <Icon className="icon--margin-right" icon="plus-square" />
            Add Container
          </Button>
        </Authorize>
      ) : (
        <Authorize
          abilityPermissions={[
            { resource: 'Trailers', permissions: ['create'] },
          ]}>
          <Button
            onClick={() => turnModal(true)}
            inverse
            testSelector="equipments_header_add-trailer_btn"
            className="float-end">
            <Icon className="icon--margin-right" icon="plus-square" />
            Add Trailer
          </Button>
        </Authorize>
      )}

      {showAddTrailer && (
        <AddTrailer
          isTrailer={!isContainers}
          showAddTrailer={showAddTrailer}
          closeAddTrailer={() => turnModal(false)}
        />
      )}
      {showBulkUpload && (
        <BulkUpload
          showModal={showBulkUpload}
          closeModal={() => turnBulkUpload(false)}
        />
      )}
    </Header>
  );
};

export default EquipmentsHeader;
