import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import Button from 'components/shared/Button';
import Input, { Textarea } from 'components/shared/Input';
import Select from 'components/shared/Select';
import { connect } from 'react-redux';
import targetValue from 'utils/targetValue';
import actions from 'store/actions/OrderDetails';
import { useNonBillableReasons } from 'api/v2/orders/nonbillable-reasons';
import { revertNonBillableOptions } from './helpers';

const EditFinancials = ({
  showEditFinancials,
  setShowEditFinancials,
  editFinancials,
  setValue,
  init,
  saveEdit,
}) => {
  const { data: reasons } = useNonBillableReasons({ shown: 1 });

  const nonBillableOptions = useMemo(() => {
    if (!reasons) return [];

    return [
      ...reasons.map(reason => ({
        value: reason.id,
        label: reason.description,
      })),
      { value: 0, label: 'Other' },
    ];
  }, [reasons]);

  useEffect(() => {
    init();
  }, []);

  const { isNonBillable, billableCode, customDescription } = editFinancials;

  const isDisabled = useMemo(() => {
    if (!billableCode && billableCode !== 0) {
      return true;
    }
    if (isNonBillable && billableCode === 0 && !customDescription?.trim()) {
      return true;
    }
    return false;
  }, [isNonBillable, billableCode, customDescription]);

  const selectOptions = isNonBillable
    ? nonBillableOptions
    : revertNonBillableOptions;

  return (
    <div className="inmodal">
      <Modal
        className="modal-container"
        show={showEditFinancials}
        onHide={() => setShowEditFinancials(false)}>
        <form
          onSubmit={e => saveEdit(e, setShowEditFinancials(false))}
          className="m-t"
          role="form"
          encType="multipart/form-data">
          <Modal.Header>
            <h4 className="modal-title">Edit Financials</h4>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => {
                    setValue(e, 'isNonBillable');
                    setValue('', 'billableCode');
                    setValue('', 'customDescription');
                  }}
                  isChecked={isNonBillable}
                  label="Non Billable"
                  type="checkbox"
                  testSelector="order_details_edit_financials_billable_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  placeholder="Non Billing Reason"
                  value={
                    selectOptions.find(item => item.value === billableCode) ||
                    null
                  }
                  onChange={item => {
                    setValue(item.value, 'billableCode');
                    if (item.value !== 0 && isNonBillable) {
                      setValue('', 'customDescription');
                    }
                  }}
                  options={selectOptions}
                />
              </div>
              {billableCode === 0 && isNonBillable && (
                <div className="form-group has-feedback col-md-12">
                  <Textarea
                    label="Custom description"
                    onChange={e =>
                      setValue(e.target.value, 'customDescription')
                    }
                    value={customDescription}
                    maxLength={255}
                    testSelector="edit-financial_order-details_input"
                  />
                </div>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setShowEditFinancials(false)}
              colour="white"
              testSelector="order-details_edit-financials_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={isDisabled}
              testSelector="order-details_edit-financials_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  editFinancials: state.orderDetails.editFinancials.info,
});

const mapDispatchToProps = dispatch => ({
  init: () => dispatch(actions.initEditFinancials()),
  setValue: (e, name) => {
    dispatch(actions.setValueEditFinancials(targetValue(e), name));
  },
  saveEdit: (e, closeEdit) => {
    e.preventDefault();
    dispatch(actions.saveEditFinancials(closeEdit));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditFinancials);
