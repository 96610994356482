import React, { useState } from 'react';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Loader from 'components/shared/Loader';
import EditFinancials from './EditFinancials';
import { orderBillingStatus } from './helpers';

const Financials = ({ orderId, billingStatus }) => {
  const [showEditFinancials, setShowEditFinancials] = useState(false);
  
  if (!orderId) {
    return <Loader />;
  }

  return (
    <>
      {![21, 22, 23].includes(Number(billingStatus)) ? (
        <Button
          onClick={() => setShowEditFinancials(true)}
          testSelector="order-details_edit-fianancials">
          Edit Financials
          <Icon className="icon--margin-left" icon="pencil" />
        </Button>
      ) : (
        <div className="font-bold text-danger">{`Billing Status is ${
          orderBillingStatus[billingStatus]
        } `}</div>
      )}

      {showEditFinancials && (
        <EditFinancials
          showEditFinancials={showEditFinancials}
          setShowEditFinancials={setShowEditFinancials}
        />
      )}
    </>
  );
};
export default Financials;
