import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import Authorize from 'components/common/Authorize';

import Header from './Header';
import EquipmentList from './EquipmentList';

const Trailers = () => {
  const { pathname } = useLocation();
  const isContainers = useMemo(() => pathname.split('/')[3] === 'containers', [
    pathname,
  ]);

  return (
    <div>
      <Header isContainers={isContainers} />
      <Authorize
        abilityPermissions={[
          {
            resource: isContainers ? 'Containers' : 'Trailers',
            permissions: ['read'],
          },
        ]}>
        <EquipmentList isContainers={isContainers} />
      </Authorize>
    </div>
  );
};
export default Trailers;
