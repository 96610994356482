const { client } = require('api/client');

export const getContainers = async (params) => {
  const { data } = await client.get('v2/customer/containers', {
    params
  });
  return data.data;
}

/**
 * @returns {Promise<Array>}
 */
export const getDriverContainers = async (driverId, districtIds) => {
  const { data } = await client.get(
    `v2/driver/containers/${driverId}`,
    {
      params: { districtIds  }
    }
  );
  return data.data.containers;
};
