import { createSelector } from 'reselect';

const jobSelector = state => state.resourceReducer.jobs.jobs;
const customerIdSelector = state =>
  state.equipmentTrailers.equipmentStatus.customerId;

export const jobsFilter = createSelector(
  [jobSelector, customerIdSelector],
  (jobs, customerId) =>
    jobs.filter(
      item => !item.status && Number(customerId) === Number(item.customer_id),
    ),
);
