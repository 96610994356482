import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import actions from 'store/actions/Equipments';
import { useCustomers } from 'api/v2/customers';
import { useContainers } from 'api/v2/containers';
import { useDebounce, useSearchParams } from 'helpers/hooks';

import Link from 'components/common/Link';
import Authorize, { authorize } from 'components/common/Authorize';
import { trailerLocation } from 'components/globalInfo/trailerLocation';
import { deleteAlert } from 'components/sweetalert';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Select from 'components/shared/Select';
import Label from 'components/shared/Label';
import Input from 'components/shared/Input';
import Pagination from 'components/shared/Pagination';
import Loader from 'components/shared/Loader';

import targetValue from 'utils/targetValue';
import Status from './StatusModal';
import Location from './LocationModal';
import History from './HistoryModal';
import EditContainer from './EditContainerModal';

const Trailers = ({
  isContainers,
  setEquipmentData,
  setLocationData,
  getHistory,
  clearHistory,
  setEquipmentEditData,
  setCustomerId,
  customerId,
  deleteContainer,
}) => {
  const history = useHistory();
  const searchParams = useSearchParams();
  const equipmentType = useMemo(() => (isContainers ? 2 : 1), [isContainers]);

  const [filterNumber, setFilterNumber] = useState('');
  const handleNumberChange = e => setFilterNumber(targetValue(e));
  const equipmentNumber = useDebounce(filterNumber, 500);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const { data, isLoading, isPlaceholderData } = useContainers({
    customerId,
    equipmentNumber,
    page: page - 1,
    limit: pageSize,
    type: equipmentType,
  });
  console.log(data);

  const [showStatus, turnStatus] = useState(false);
  const [showLocation, turnLocation] = useState(false);
  const [showHistory, turnHistory] = useState(false);
  const [showEditContainer, turnEditContainer] = useState(false);

  useEffect(() => {
    const customer = searchParams.get('customerId');
    if (customer) {
      setCustomerId(Number(customer));
    }
  }, [searchParams, setCustomerId]);

  const { data: customers } = useCustomers();
  const customerList = useMemo(
    () => [
      { value: '', label: 'All customers' },
      ...customers.map(customer => ({
        value: customer.id,
        label: `${customer.id} | ${customer.name}`,
      })),
    ],
    [customers],
  );

  const renderEquipmentItem = trailer => (
    <tr key={`${trailer.id}-${trailer.equipmentNumber}`}>
      <td>{trailer.id}</td>
      <td>{trailer.equipmentNumber}</td>
      <td>
        {trailer.status === 3 && <Label type="primary">Empty</Label>}
        {trailer.status === 1 && trailer.locationType !== 3 && (
          <Label type="success">Staged</Label>
        )}
        {trailer.status === 1 && trailer.locationType === 3 && (
          <Label type="success">Full</Label>
        )}
        {trailer.status === 2 && <Label type="warning">Unloading</Label>}
        {trailer.status === 3 && trailer.locationType === 3 && (
          <Label type="info">En route to Origin</Label>
        )}
        {trailer.status === 1 && trailer.locationType === 3 && (
          <Label type="info">En route to Destination</Label>
        )}
      </td>
      <td>
        <div className="text-left">
          {trailer.locationType === 1 && (
            <span>
              <Icon icon="sand_site" />
              <strong> {trailerLocation[trailer.locationType].name} / </strong>
              <Link to="location-details" id={trailer.locationId}>
                {trailer.locationName ?? 'unknown'}
              </Link>
            </span>
          )}
          {trailer.locationType === 2 && (
            <span>
              <Icon icon="well_site" />
              <strong> {trailerLocation[trailer.locationType].name} / </strong>
              <Link to="location-details" id={trailer.locationId}>
                {trailer.locationName ?? 'unknown'}
              </Link>
            </span>
          )}
          {trailer.locationType === 3 && (
            <span>
              <Icon icon="truck" />
              <strong> {trailerLocation[trailer.locationType].name} / </strong>
              <Link to="driver-details" id={trailer.locationId}>
                {trailer.locationName ?? 'unknown'}
              </Link>
            </span>
          )}
          {trailer.locationType === 4 && (
            <span>
              <Icon icon="job" />
              <strong> {trailerLocation[trailer.locationType].name} / </strong>
              <Link to="job-details" id={trailer.locationId}>
                {trailer.locationName ?? 'unknown'}
              </Link>
            </span>
          )}
          {trailer.locationType === 5 && (
            <span>
              <Icon icon="acid_facility" width={40} height={40} />
              <strong> {trailerLocation[trailer.locationType].name} / </strong>
              <Link to="location-details" id={trailer.locationId}>
                {trailer.locationName ?? 'unknown'}
              </Link>
            </span>
          )}
        </div>
      </td>
      <td>
        <span>
          <Icon icon="well_site" />
          <Link to="location-details" id={trailer.locationId}>
            {trailer.homeLocationName ?? 'unknown'}
          </Link>
        </span>
      </td>
      <td>
        <strong>{trailer.containerVendorName ?? '-'}</strong>
      </td>
      <td>
        {trailer.locationType === 3 ? (
          <span>
            <strong> Carrier: </strong>{' '}
            <Link to="carrier-details" id={trailer.carrierId}>
              {trailer.carrierName}
            </Link>
            <br />
            <strong> Truck # {trailer.truckNo} / Order </strong>
            <Link to="order-details" id={trailer.orderId}>
              {trailer.orderId ? `#${trailer.orderId}` : ''}
            </Link>
          </span>
        ) : (
          <strong className="text-center">-</strong>
        )}
      </td>
      <td>
        {(authorize({
          abilityPermissions: [
            { resource: 'Trailers', permissions: ['update'] },
          ],
        }) ||
          authorize({
            abilityPermissions: [
              { resource: 'Containers', permissions: ['update'] },
            ],
          })) && (
          <>
            <Button
              onClick={() => {
                turnStatus(true);
                setEquipmentData(trailer);
              }}
              testSelector="equipments_trailers_status_btn"
              colour="white"
              theme="small">
              <Icon icon="pencil" className="icon--margin-right" />
              Status
            </Button>
            <Button
              onClick={() => {
                turnLocation(true);
                setLocationData(trailer);
              }}
              testSelector="equipments_trailers_location_btn"
              colour="white"
              theme="small">
              <Icon icon="pencil" className="icon--margin-right" />
              location
            </Button>
            {isContainers && (
              <Authorize
                abilityPermissions={[
                  {
                    resource: 'Containers',
                    permissions: ['update'],
                  },
                ]}>
                <Button
                  onClick={() => {
                    getHistory(trailer.customerId, trailer.id);
                    setEquipmentEditData(trailer);
                    turnEditContainer(true);
                  }}
                  testSelector="equipments_trailers_edit_btn"
                  colour="green"
                  theme="small">
                  <Icon icon="pencil" className="icon--margin-right" />
                  edit
                </Button>
              </Authorize>
            )}
            <Button
              onClick={() => {
                getHistory(
                  trailer.customerId,
                  trailer.id,
                  trailer.locationName,
                );
                turnHistory(true);
              }}
              testSelector="equipments_trailers_history_btn"
              colour="white"
              theme="small">
              <Icon icon="history" className="icon--margin-right" />
              history
            </Button>
            {isContainers && (
              <Authorize
                abilityPermissions={[
                  {
                    resource: 'Containers',
                    permissions: ['delete'],
                  },
                ]}>
                <Button
                  onClick={() => deleteAlert(deleteContainer, trailer.id)}
                  testSelector="equipments_trailers_delete_btn"
                  colour="white"
                  theme="small">
                  <Icon icon="trash" className="icon--margin-right" />
                  delete
                </Button>
              </Authorize>
            )}
          </>
        )}
      </td>
    </tr>
  );

  return (
    <div className="row">
      {showStatus && (
        <Status
          equipmentType={equipmentType}
          showStatus={showStatus}
          closeStatus={() => turnStatus(false)}
        />
      )}
      {showLocation && (
        <Location
          equipmentType={equipmentType}
          showLocation={showLocation}
          closeLocation={() => turnLocation(false)}
        />
      )}
      {showEditContainer && (
        <EditContainer
          showEditContainer={showEditContainer}
          closeEditContainer={() => turnEditContainer(false)}
        />
      )}
      {showHistory && (
        <History
          showHistory={showHistory}
          closeHistory={() => {
            clearHistory();
            turnHistory(false);
          }}
        />
      )}
      <div className="ibox float-e-margins">
        <div className="ibox-title">
          <Authorize access={['CUSTOMER']}>
            <h5>{isContainers ? 'Containers' : 'Trailers'} list</h5>
          </Authorize>
          <Authorize forbidden={['CUSTOMER']}>
            <div className="row">
              {isContainers && (
                <div className="col-lg-3">
                  <Input
                    type="text"
                    value={filterNumber || ''}
                    onChange={handleNumberChange}
                    label="Equipment Number"
                    testSelector="equipment-trailers_trailers_number_input"
                  />
                </div>
              )}
              <div className="col-lg-3">
                <Select
                  options={customerList}
                  value={customerList?.find(item => item.value === customerId)}
                  onChange={item => {
                    searchParams.set('customerId', item.value);
                    history.replace({ search: searchParams.toString() });
                    setCustomerId(item.value);
                  }}
                  placeholder="Please Select a Customer"
                  testSelector="equipment-trailers_trailers_customer_select"
                />
              </div>
            </div>
          </Authorize>
        </div>
        <div>
          <Pagination
            withLimit
            onPageChange={newPage => setPage(newPage.currentPage)}
            onLimitChange={limit => {
              setPageSize(limit);
              setPage(1);
            }}
            currentLimit={pageSize}
            currentPage={page}
            totalPages={data?.totalPages ?? 10}
            isDisabled={isPlaceholderData}
          />
        </div>
        <div className="ibox-content">
          <table className="table table--hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Equipment №</th>
                <th>Status</th>
                <th>Location</th>
                <th>Home Location</th>
                <th>Vendor</th>
                <th>Order info</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <Loader />
              ) : (
                data?.equipments?.map(renderEquipmentItem)
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  customerId: state.equipmentTrailers.equipmentStatus.customerId,
});

const mapDispatchToProps = dispatch => ({
  setCustomerId: id => dispatch(actions.setCustomerId(id)),
  setEquipmentData: data => dispatch(actions.setEquipmentData(data)),
  setLocationData: data => dispatch(actions.setLocationData(data)),
  getHistory: (customerId, trailerId) =>
    dispatch(actions.getHistory(customerId, trailerId)),
  clearHistory: () => dispatch(actions.clearHistory()),
  setEquipmentEditData: data => dispatch(actions.setEquipmentEditData(data)),
  deleteContainer: containerId =>
    dispatch(actions.deleteContainer(containerId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Trailers);
