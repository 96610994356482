import React, { useEffect, useMemo } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import { useLocations } from 'api/v2/locations';
import { useDrivers } from 'api/v2/drivers';
import actions from 'store/actions/Equipments';
import { resourceActions } from 'store/modules/resourceReducer/actions';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import { trailerLocation } from 'components/globalInfo/trailerLocation';

import { jobsFilter } from './helpers';

const Location = ({
  jobs,
  getJobs,
  closeModalEquipment,
  showLocation,
  closeLocation,
  setLocationType,
  setLocationId,
  setLocationComment,
  updateLocation,
  equipmentLocation,
}) => {
  const { locationType, locationId, locationComment } = equipmentLocation;
  const { data: locationsList } = useLocations();

  const { data: drivers } = useDrivers();

  useEffect(() => {
    getJobs();
    return () => closeModalEquipment();
  }, []);

  const locationTypeList = Object.values(trailerLocation).map(item => ({
    value: item.id,
    label: item.name,
  }));
  const locations = useMemo(() => {
    switch (locationType) {
      case 1:
        // sand sites
        return locationsList.filter(loc => loc.locationType === 2);
      case 2:
        // well sites
        return locationsList.filter(loc => loc.locationType === 1);
      case 3:
        return drivers;
      case 4:
        return jobs;
      case 5:
        // acid facilities
        return locationsList.filter(loc => loc.locationType === 5);
      default:
        return [];
    }
  }, [locationType, locationsList, drivers, jobs]);

  const locationOptions = locations
    .filter(
      location =>
        !location.is_deleted ||
        (location.is_deleted && location.is_deleted === 0),
    )
    .map(location => ({
      value: location.id,
      label: `${location.id} | ${
        locationType === 4 ? location.job_name : location.name
      }`,
    }));

  return (
    <Modal
      style={{ top: '10%' }}
      className="modal-container"
      show={showLocation}
      onHide={closeLocation}>
      <form onSubmit={updateLocation} className="m-t">
        <Modal.Header>
          <h4 className="modal-title">Edit equipment location</h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Select
                options={locationTypeList}
                value={locationTypeList.find(
                  item => item.value === locationType,
                )}
                onChange={item => setLocationType(item.value)}
                placeholder="Please select a Location type"
                testSelector="equipment-trailers_location_type_select"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              {locationType === 6 ? (
                <Input
                  onChange={e => setLocationComment(e.target.value)}
                  label="Comment"
                  value={locationComment || ''}
                  testSelector="equipment-trailers_location_comment_input"
                />
              ) : (
                <Select
                  options={locationOptions}
                  value={locationOptions.find(
                    item => item.value === locationId,
                  )}
                  onChange={item => setLocationId(item.value)}
                  placeholder="Please select a Location"
                  testSelector="equipment-trailers_location_location_select"
                />
              )}
            </div>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={closeLocation}
            colour="white"
            testSelector="equipments_location_close_btn">
            Close
          </Button>
          <Button type="submit" testSelector="equipments_location_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  equipmentLocation: state.equipmentTrailers.equipmentLocation,
  sandSites: state.lookUp.sandSites,
  wellSites: state.lookUp.wellSites,
  jobs: jobsFilter(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setLocationToType: e => actions.setLocationToType(e.target.value),
  setLocations: locations => dispatch(actions.setLocations(locations)),
  getJobs: () => dispatch(resourceActions.getJobs()),
  setLocationType: value => dispatch(actions.setLocationType(value)),
  setLocationId: value => dispatch(actions.setLocationId(value)),
  setLocationComment: value => dispatch(actions.setLocationComment(value)),
  closeModalEquipment: () => dispatch(actions.closeModalEquipment()),
  updateLocation: e => {
    e.preventDefault();
    dispatch(
      actions.updateLocation(ownProps.closeLocation, ownProps.equipmentType),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Location);
