import React from 'react';

const StagingSite = () => {
  const gradientId = React.useId();
  
  return (
    <svg
      viewBox="1142.2 830.39 23 23"
      xmlns="http://www.w3.org/2000/svg"
      className="icon--fill"
    >
      <linearGradient id={gradientId} gradientUnits="userSpaceOnUse" x1="-1120.873" y1="831.504" x2="-1120.873" y2="831.52" gradientTransform="translate(1416631.875 -1049221.375) scale(1262.8352)">
        <stop offset="0" style={{ stopColor: "#FFFFFF" }} />
        <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
      </linearGradient>
      <circle fill={`url(#${gradientId})`} cx="1153.7" cy="841.89" r="11.5" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 2.2737367544323206e-13, 1.1368683772161603e-13)" />
      <text style={{ fill: "#000000", fontFamily: "Arial, sans-serif", fontSize: "17.9px", whiteSpace: "pre" }} x="1147.884" y="848.527" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 2.2737367544323206e-13, 1.1368683772161603e-13)">S</text>
    </svg>
  );
};

export default StagingSite;
