import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import ContestModal from 'components/shared/ContestModal';
import ModalHistory from 'components/views/OrderDetails/Header/HistoryModal';
import UploadTicket from 'components/views/Reconcile/OrderDetails/UploadTicket';
import Authorize, { authorize } from 'components/common/Authorize';
import Link from 'components/common/Link';
import DropdownButton from 'components/shared/DropdownButton';
import TagsList from 'components/views/Reconcile/OrderDetails/OrderInfoTagsList';
import OrderInfoColumns from 'components/views/Reconcile/OrderDetails/OrderInfoColumns';
import Price from 'components/views/Reconcile/OrderDetails/Price';
import Timeline from 'components/views/Reconcile/OrderDetails/Timeline';
import OrderInfoTicket from 'components/views/Reconcile/OrderDetails/OrderInfoTicket';
import Modify from 'components/views/OrderActions/Modify/index';
import DemurrageReason from 'components/views/OrderActions/DemurrageReason/index';
import { useDemurrageModal } from 'components/views/OrderActions/hooks';

import orderStoreActions from 'store/actions/Orders';
import authService from 'services/auth/service';
import { ticketsDownloadAllTickets } from 'store/actions/OrderTickets';
import { useOrder, useOrderTickets } from 'api/v2/orders';
import { useOrderModals } from './hooks';

const user = authService.getUser();

const BillableOrderDetails = ({ orderId, jobPlanId, timeFrame }) => {
  const [missingTicketsArray] = useState([]);
  const { data: orderDetails, isLoading, refetch: refetchOrder } = useOrder({
    orderId,
  });
  const { data: orderTickets, refetch: refetchTickets } = useOrderTickets({
    orderId,
  });

  const order = useMemo(() => orderDetails?.info, [orderDetails]);
  const { openModals, toggleModal } = useOrderModals();
  const demurrageModal = useDemurrageModal();

  const dispatch = useDispatch();
  const handleApproveOrder = useCallback(
    () =>
      dispatch(
        orderStoreActions.jobPlansApproveOrder([orderId], {
          id: jobPlanId,
          start: timeFrame.start,
          end: timeFrame.end,
        }),
      ),
    [dispatch, jobPlanId, orderId, timeFrame.end, timeFrame.start],
  );

  const handleDownloadAll = useCallback(
    () => dispatch(ticketsDownloadAllTickets(orderId)),
    [dispatch, orderId],
  );

  const handleRefresh = () => {
    refetchOrder();
    refetchTickets();
  };

  const handleShowDemurrageReason = data => {
    if (
      (Number(data.sandDemurrage.price) !== 0 &&
        !data.sandSiteDemurrageReasonId) ||
      (Number(data.wellDemurrage.price) !== 0 &&
        !data.wellSiteDemurrageReasonId)
    ) {
      demurrageModal.toggle(data);
    } else {
      // setOpenSection(-1);
    }
  };

  const actions = useMemo(() => {
    const actionOptions = [];

    if (!order) {
      return [];
    }

    if (
      order.billing.approvalsRequired?.length &&
      order.billing.approvalsRequired.includes(user.role) &&
      order.billing_status <= 20
    ) {
      if (order.review_status !== 2  && order.status === 4) {
        actionOptions.push({
          name: 'approve',
          label: 'Approve',
          onClick: handleApproveOrder,
        });
      }
      if (order.review_status !== 4 && order.status === 4) {
        actionOptions.push({
          name: 'contest',
          label: 'Contest',
          onClick: toggleModal('contest'),
        });
      }
    }

    if (order.status === 4) {
      actionOptions.push({
        name: 'history',
        label: 'History',
        onClick: toggleModal('history'),
      });
    }

    if (order.status > 1) {
      actionOptions.push({
        name: 'uploadTicket',
        label: 'Upload Ticket',
        onClick: toggleModal('uploadTicket'),
      });
    }
    return actionOptions;
  }, [order, handleApproveOrder, toggleModal]);

  if (isLoading || !order) {
    return <Loader />;
  }

  return (
    <div>
      <div className="marginBottom15">
        <div className="order-info__header flexBetweenCenter border-bottom-light marginBottom15">
          <h3 className="order-info__title">
            <span className="order-info__title__text textTransformCapitalize">
              Order #
              {authorize({
                abilityPermissions: [
                  {
                    resource: 'Orders',
                    permissions: ['read'],
                  },
                ],
              }) ? (
                <Link to="order-details" id={orderId} target="_blank">
                  {orderId}
                </Link>
              ) : (
                orderId
              )}
            </span>
          </h3>
          <div className="flexAlignCenter">
            <Authorize
              abilityPermissions={[
                { resource: 'Orders', permissions: ['update'] },
              ]}>
              <Button
                theme="small marginBottom0"
                inverse={openModals.modify}
                onClick={toggleModal('modify')}
                testSelector="jobplans_order-details_info_modify_btn">
                Modify
              </Button>
            </Authorize>
            <Authorize
              abilityPermissions={[
                {
                  resource: 'Reconcile',
                  permissions: ['update'],
                },
              ]}>
              <DropdownButton
                theme="small"
                text="Actions"
                icon="down"
                options={actions}
                noMargin
                testSelector="jobplans_order-details_info_actions_dropdown"
              />
            </Authorize>
          </div>
        </div>
        <div className="marginBottom15">
          <h3>Tags</h3>
          <TagsList data={order} />
        </div>

        <div className="reconcile">
          <div className="slide-panel__content">
            <div className="order-info__info">
              <OrderInfoColumns
                data={order}
                tickets={orderTickets?.tickets || []}
                config={orderTickets?.config || []}
                jobPlansdata={{
                  id: jobPlanId,
                  start: timeFrame.start,
                  end: timeFrame.end,
                }}
              />
            </div>
            <Price data={order} />

            <div className="order-info__documents-timeline">
              <div className="order-info__documents">
                <div className="order-info__documents__header">
                  <h3 className="order-info__documents__title">Documents</h3>
                  <Button
                    onClick={handleDownloadAll}
                    theme="small"
                    testSelector="job-plans_order-details_info_download-all_btn">
                    Download All
                  </Button>
                </div>

                <div className="order-info__documents__tickets">
                  {(orderTickets?.tickets || [])
                    .filter(
                      item => item.ticketType !== 1 && item.ticketType !== 4,
                    )
                    .map(
                      ticket =>
                        !ticket.isDeleted && (
                          <OrderInfoTicket
                            key={ticket.id}
                            ticket={ticket}
                            order={order}
                          />
                        ),
                    )}
                </div>
                {missingTicketsArray.length > 0 && (
                  <>
                    <br />
                    <h4 style={{ color: 'red' }}>Missing Documents</h4>
                    <div className="order-info__documents__tickets">
                      {missingTicketsArray.map(ticket => (
                        <div
                          className="ticket"
                          style={{
                            color: 'red',
                            borderColor: 'red',
                          }}
                          key={ticket}>
                          <div className="ticket__header">
                            <span className="ticket__header__text">
                              {ticket}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>

              <div className="order-info__timeline">
                <h3 className="order-info__heading">Timeline</h3>
                <Timeline data={order} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modify
        orderId={orderId}
        openModal={openModals.modify}
        turnModal={toggleModal('modify')}
        onSuccess={handleShowDemurrageReason}
      />
      <ContestModal
        open={openModals.contest}
        onClose={() => {
          toggleModal('contest')();
        }}
        orderIds={[orderId]}
        jobPlansdata={{
          id: jobPlanId,
          start: timeFrame.start,
          end: timeFrame.end,
        }}
      />
      {openModals.history && (
        <ModalHistory
          showHistory={openModals.history}
          closeHistory={toggleModal('history')}
          outsideOrderId={orderId}
        />
      )}
      {openModals.uploadTicket && (
        <UploadTicket
          openModal={openModals.uploadTicket}
          turnModal={toggleModal('uploadTicket')}
          order={order}
          onClose={handleRefresh}
        />
      )}

      <DemurrageReason
        orderId={orderId}
        openModal={demurrageModal.show}
        turnModal={demurrageModal.toggle}
        demurrageData={demurrageModal.data}
      />
    </div>
  );
};
export default BillableOrderDetails;
