import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Row } from 'react-bootstrap';

import actions from 'store/actions/Equipments';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import eventTarget from 'utils/dispatchWithFieldValue';

import { useVendors } from 'api/v2/vendors';
import { useCustomerLocations } from 'api/v2/locations';
import { useCustomers } from 'api/v2/customers';

const EditContainer = ({
  showEditContainer,
  closeEditContainer,
  setEditCustomer,
  setEditEquipmentNumber,
  setEditContainerVendor,
  setEditContainerHomeLocation,
  editContainerData,
  editContainer,
}) => {
  const {
    customerId,
    containerNumber,
    containerVendorId,
    homeLocation,
  } = editContainerData;
  const [originalContainerVendor] = useState(containerVendorId.value);

  const { data: vendors } = useVendors();
  const { data: customers } = useCustomers();
  const { data: wellsites } = useCustomerLocations(customerId.value);
  const wellSitesList = useMemo(() => {
    if (!wellsites) return [];
    return wellsites.map(item => ({
      value: item.id,
      label: `${item.id} | ${item.name}`,
    }));
  }, [wellsites]);

  const saveDisabled = useMemo(() => {
    if (
      !customerId ||
      !customerId.value ||
      !containerNumber.trim() ||
      (!containerVendorId || !containerVendorId.value) ||
      (!homeLocation || !homeLocation.value)
    ) {
      return true;
    }
    return false;
  }, [customerId, containerNumber, containerVendorId, homeLocation]);

  return (
    <Modal
      style={{ top: '10%' }}
      className="modal-container"
      show={showEditContainer}
      onHide={closeEditContainer}>
      <form
        onSubmit={e =>
          editContainer(e, originalContainerVendor !== containerVendorId.value)
        }
        className="m-t"
        role="form">
        <Modal.Header>
          <h4 className="modal-title">Edit Container</h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Select
                label="Select Customer"
                options={customers.map(customer => ({
                  value: customer.id,
                  label: customer.name,
                }))}
                onChange={setEditCustomer}
                value={customerId}
                required
                testSelector="equipment-trailers_edit-container_customer_select"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                label="Equipment number"
                onChange={setEditEquipmentNumber}
                value={containerNumber}
                required
                testSelector="equipment-trailers_edit-container_number_input"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Select
                placeholder="Vendor"
                options={vendors.map(vendor => ({
                  value: vendor.id,
                  label: vendor.name,
                }))}
                onChange={setEditContainerVendor}
                value={containerVendorId}
                required
                testSelector="equipment-trailers_edit-container_vendor_select"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Select
                placeholder="Home Location"
                options={wellSitesList}
                onChange={setEditContainerHomeLocation}
                value={homeLocation.value ? homeLocation : null}
                required
                testSelector="equipment-trailers_edit-container_home_location_select"
              />
            </div>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={closeEditContainer}
            testSelector="equipments_edit-container_close_btn"
            colour="white">
            Close
          </Button>
          <Button
            type="submit"
            disabled={saveDisabled}
            testSelector="equipments_edit-container_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  editContainerData: state.equipmentTrailers.editContainer,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setEditCustomer: customer => {
    dispatch(actions.setEditCustomer(customer));
  },
  setEditEquipmentNumber: eventTarget(actions.setEditEquipmentNumber, dispatch),
  setEditContainerVendor: containerVendor => {
    dispatch(actions.setEditContainerVendor(containerVendor));
  },
  setEditContainerHomeLocation: homeLocation => {
    dispatch(actions.setEditContainerHomeLocation(homeLocation));
  },
  editContainer: (e, updateVendor) => {
    e.preventDefault();
    dispatch(actions.editContainer(ownProps.closeEditContainer, updateVendor));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditContainer);
